import { ChipType, InstrumentType } from "./instrument-type-model";
import { DeferredJobOptionEntryPoint, JobTaskOption } from "./job-options-model";
import { RunStateValue } from "./run-qc-model";

export type Run = {
    summary: RunSummary
    design: RunDesign
}

export type AutomationParameter = {
    name: string
    valueDataType: string
    simpleValue: string
}

export type RunSummary = {
    uniqueId: string;
    cchemistrySwVersion: string;
    chipType: ChipType;
    instrumentType: InstrumentType;
    context: string;
    createdAt: string;
    createdBy: string;
    instrumentName: string;
    instrumentSerialNumber: string;
    instrumentSwVersion: string;
    name: string;
    numCellsCompleted: number;
    numCellsFailed: number;
    numLRCells: number;
    numStandardCells: number;
    primaryAnalysisSwVersion: string;
    reserved: boolean;
    status: RunStateValue;
    summary: string;
    totalCells: number;
    completedAt: string;
    startedAt: string;
    transfersCompletedAt: string;
}

export type RunDesign = {
    experimentId: string;
    experimentName: string;
    experimentDescription: string;
    runName: string;
    runDescription: string;
    uuid: string;
    chipType: ChipType;
    instrumentType: InstrumentType;
    samples: Sample[];
    multiJobId: number;
    createdBy: string;
}

export type Sample = {
    sampleName: string;
    sampleDescription: string;
    biosampleName: string;
    wellName: string;
    plateNumber: number;
    labelNumber: string;
    application: string;
    libraryType: string;
    insertSize: number;
    loadingConcentration: number;
    isBarcoded: boolean;
    movieTimeHours: number;
    automationName: string;
    ccsMode: SupportedExecutionModes;
    includeKinetics: boolean;
    includeCpG: boolean;
    templatePrepKit: string;
    bindingKit: string;
    sequencingKit: string;
    controlKit: string;
    useDynamicLoading: boolean;
    loadingTarget?: number;
    maxLoadingTimeHours?: number;
    usePreExtension?: boolean;
    extensionTime?: number;
    immobilizationTime?: number;
    primaryAutomationName: string;
    copyFiles: string[];
    copyFilesPreset: string;
    symmetricBarcodes: boolean;
    minBarcodeScore: number;  // XXX obsolete, should remove
    metricsVerbosity: string;
    readout: string;
    barcodeUuid: string;
    autoAnalysisEnabled: boolean;
    adapter: string;
    readSegmentation: boolean;
    scIsoSeq: boolean;
    cellType: string;
    demuxMode: SupportedExecutionModes;
    pipelineId: string;
    AutoAnalysisName?: string
    analysisName?: string // TODO: "AutoAnalysisName" or "analysisName" ???
    entryPoints?: DeferredJobOptionEntryPoint[];
    taskOptions?: JobTaskOption[];
    presetId?: string;
    projectId: number;
    includeLowQuality: boolean;
    heteroduplexDetection: boolean;
    multiJobId: number;
    barcodedSamples: BarcodedSample[];
    uuid: string;
    ccsUuid: string;
    barcodesFasta: string;
    barcodeCsvFileName: string;
    emitSubreadsPercent: number;
    automationParameters: AutomationParameter[];
    consensusMode: string;
    subreadToHiFiPileup?: boolean;
    fullResolutionBaseQual?: boolean;
}

export interface SampleData {
    assayPlateId: string
    assayPlateWell: string
}

export type BarcodedSample = {
    dnaBarcodeName: string;
    bioSampleName: string;
    pipelineId?: string;
    analysisName?: string;
    entryPoints?: DeferredJobOptionEntryPoint[];
    taskOptions?: JobTaskOption[];
    uuid: string;
    sampleData: SampleData
}

export enum SupportedExecutionModes {
    none = "None",
    oninstrument = "OnInstrument",
    offinstrument = "OffInstrument"
}

export const defaultBarcodedSample: BarcodedSample = {
    dnaBarcodeName: "",
    bioSampleName: "",
    uuid: "",
    sampleData: null
}

export const defaultSample: Sample = {
    sampleName: "",
    sampleDescription: "",
    biosampleName: "",
    wellName: "",
    plateNumber: 1,
    labelNumber: "",
    application: "",
    libraryType: "",
    insertSize: 0,
    loadingConcentration: 0,
    isBarcoded: false,
    movieTimeHours: 0,
    automationName: "",
    ccsMode: SupportedExecutionModes.none,
    includeKinetics: false,
    includeCpG: false,
    templatePrepKit: "",
    bindingKit: "",
    sequencingKit: "",
    controlKit: "",
    useDynamicLoading: false,
    primaryAutomationName: "",
    copyFiles: [],
    copyFilesPreset: "Production",
    symmetricBarcodes: false,
    minBarcodeScore: 0,
    metricsVerbosity: null,
    readout: null,
    barcodeUuid: "",
    demuxMode: SupportedExecutionModes.none,
    pipelineId: "",
    AutoAnalysisName: "",
    analysisName: "", // TODO: "AutoAnalysisName" or "analysisName" ??
    autoAnalysisEnabled: false,
    adapter: "mas16",
    readSegmentation: false,
    scIsoSeq: false,
    cellType: "human",
    entryPoints: [],
    taskOptions: [],
    projectId: 0,
    includeLowQuality: false,
    heteroduplexDetection: false,
    multiJobId: 0,
    barcodedSamples: [],
    uuid: "",
    ccsUuid: "",
    barcodesFasta: "",
    barcodeCsvFileName: "",
    emitSubreadsPercent: 0,
    automationParameters: [],
    consensusMode: "molecule"
}

export const defaultRunDesign: RunDesign = {
    experimentId: "",
    experimentName: "",
    experimentDescription: "",
    runName: "",
    runDescription: "",
    uuid: "",
    chipType: "8mChip",
    instrumentType: "Sequel2",
    samples: [defaultSample],
    multiJobId: 0,
    createdBy: ""
}
