/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, Fragment, forwardRef, useImperativeHandle, ReactNode, useRef, useCallback, useMemo, useState } from "react"
import { AgGridReact } from "ag-grid-react"
import { GridApi, ColDef, ColGroupDef, GridOptions, IDatasource, IGetRowsParams, RowClickedEvent, GridReadyEvent } from "ag-grid-community"

import { Search } from "./Search"
import { BadgeButton } from "./BadgeButton"

import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-balham.css"
import { scrollPositionLabel as computeScrollPositionLabel, CheckboxHeaderComponent, EmptyHeaderComponent }
    from "../../core/utils/ag-grid-helper"
import { SortModel, storeGridState } from "../../data/services/grid-storage-service"
import { DatasetsOrAnalysis } from "../../silos/constants"
import * as _ from "lodash"
import { useDynamicCallback } from "../../core/utils/useDynamicCallback"
import { InstrumentType } from "../../data/model/instrument-type-model"
import { SearchColumnHeader } from "./SearchColumnHeader"

const STABILITY_INTERVAL = 300 // ms after lastUpdate till we declare the grid stable

export interface IDatasetSourceWithUpdateFlag extends IDatasource {
    updateFlag: string
}

export type GridSelectionMode = "Single" | "Multiple" | "None"

export interface TableProps extends GridOptions{

    data: any[]

    loading: boolean
    tableId?: string
    tableTitle?: string
    disableSearch?: boolean
    idField: string
    nameField?: string
    pageSize?: number
    forceRefreshToggle?: boolean

    // selection
    selectionMode?: GridSelectionMode,
    selected?: any[]
    isSelectable?: (rowData: any) => boolean,
    onSelectionChange?: (selected: any[], notSelected: any[]) => void
    hideSelectAll?: boolean
    suppressSelectionHighlighting?: boolean

    // "highlighted" this overloads the ag-grid selection property to achieve row highlighting
    highlighted?: any[]

    // filter
    searchId?: string
    searchBarPlaceholder?: string
    searchBarHelpHref?: string
    onSearchChanged?: (text: string) => void

    // main cell
    mainHeaderPrependItems?: ColDef[]
    mainHeaderSectionItems?: ColDef[]

    // rest of header data
    headerData?: ColDef[]

    // cart button
    hasCart?: boolean
    modalHeader?: string
    numSelected?: number
    onCartBtnClicked?: () => void

    // sorting
    sortModel?: SortModel
    setSortModel?: (sortModel: SortModel) => void

    // if onRowClick is specified, use this to block the event if it comes from specific columns
    rowClickExcludedColumns?: string[]

    currentInstrumentType?: InstrumentType

    hideScrollPositionLabel?: boolean

    datasetsOrAnalysis?: DatasetsOrAnalysis
    children?: ReactNode

    // style to applied to the div containing the AgGridReact
    gridHeight?: string

    // If true, will resize column size when column defs change or window resizes
    autoResizeColumns?: boolean
}


export type ColumnDef = ColGroupDef | ColDef

export const columnDefId = (colDef: ColumnDef): string | null => {
    if(!colDef) {
        return null
    }
   try {
       return (colDef as ColDef).colId
   } catch {
       return (colDef as ColGroupDef).groupId
   }
}

export const Grid = forwardRef((props: TableProps, ref) => {
    const [scrollPositionLabel, setScrollPositionLabel] = useState<string>("")
    const [isGridConfigured, setIsGridConfigured] = useState<boolean>(false)
    const [isSelectAllChecked, setIsSelectAllChecked] = useState<boolean>(false)
    const [viewPortChangeFlag, setViewPortChangeFlag] = useState<boolean>(false)

    const stabilityTimer = useRef<ReturnType<typeof setTimeout>>()
    const gridApiRef = useRef<GridApi>(null)

    const loadingRef = useRef<boolean>(props.loading)
    loadingRef.current = props.loading

    const stabilityElementRef = React.createRef<HTMLDivElement>()

    // "onDestroy" useEffect
    useEffect( () => {
        return () => {
            if (stabilityTimer.current) {
                clearTimeout(stabilityTimer.current)
            }
            gridApiRef.current = null
        }
    }, [])

    const onComponentStateChanged = useCallback(() => {
        if (stabilityElementRef.current) {
            if (stabilityTimer.current) {
                clearTimeout(stabilityTimer.current)
            }
            stabilityElementRef.current.className = `${props.tableId}_is_updating`
            stabilityTimer.current = setTimeout( () => {
                    if (stabilityElementRef.current) {
                        stabilityElementRef.current.className = `${props.tableId}_is_stable`
                    }
            },  STABILITY_INTERVAL)
        }
    }, [props.tableId])

    function wrapDatasource(datasource: IDatasource) {
        if (!datasource) {
            return datasource
        }
        const newGetRows = (params: IGetRowsParams) => {
            const originalSuccessCallback = params.successCallback
            params.successCallback = (rowsThisBlock: any[], lastRow?: number) => {
                originalSuccessCallback(rowsThisBlock, lastRow)
                updateScrollPositionLabel()
            }
            return datasource.getRows(params)
        }
        return Object.assign({}, props.datasource, { getRows: newGetRows})

    }

    const datasource = useMemo(() => {
        return wrapDatasource(props.datasource)
    }, [props.datasource])

    useEffect(() => {
        if (isGridConfigured
                && gridApiRef.current
                && datasource
                && (!gridApiRef.current["infiniteRowModel"] || datasource !== gridApiRef.current["infiniteRowModel"].datasource)
            ) {
            gridApiRef.current.setDatasource(datasource)
        }
    }, [isGridConfigured, datasource])

    useEffect(() => {
        if (gridApiRef.current) {
            if (!props.datasource) {
                if (props.loading) {
                    gridApiRef.current.showLoadingOverlay()
                } else {
                    gridApiRef.current.hideOverlay()
                }
            }
        }
    }, [props.datasource, props.loading])

    const defaultColumnDef = useMemo(() => ({
        filter: true,
        sortable: true,
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
    }), [])

    const defaultPageSize = props.pageSize

    const hasColumnGroups = (): boolean => {
        if (!props.headerData) { return false }
        for (let colDef of props.headerData) {
            let gColDef = colDef as ColGroupDef
            if (gColDef.children && gColDef.children.length > 0) {
                return true
            }
        }
        return false
    }

    const getRowClass = (params) => {
        if (props.suppressSelectionHighlighting) {
            return ""
        }
        if (props.selected && props.selected.length > 0 && params.data) {
            const ids = props.selected.map(data => data[props.idField])
            if (ids.includes(params.data[props.idField])) {
                return "ag-grid-selected"
            }
        }
        if (props.highlighted) {
            const ids = props.highlighted.map( data => data[props.idField])
            if (ids.includes(params.data[props.idField])) {
                return "ag-grid-highlighted"
            }
        }
        return ""
    }

    const getIdForSelectAllCheckBox = () => {
        return "selectAll-" + props.tableId
    }

    const onToggleSelectAll = useDynamicCallback(( isChecked: boolean) => {

        setIsSelectAllChecked(isChecked)

        let all = allData(true)
        if (props.isSelectable) {
            all = allData(true).filter(props.isSelectable)
        }

        const selected = isChecked ? all : []
        const notSelected = isChecked ? [] : all
        props.onSelectionChange(selected, notSelected)
    })

    const calculateColumnDefs = () => {
        const mainHeaderColumns: ColDef[] = []

        const { selectionMode, isSelectable } = props
        if (selectionMode && selectionMode !== "None") {
            let checkboxCol: ColumnDef = {
                headerName: "",
                colId: "isChecked",
                pinned: "left" as const,
                filter: false,
                width: 50,
                sortable: false,
                cellClass: "pb-ag-grid-no-ellipsis ",
                cellRenderer: (params) => {
                    const hasCheckbox = params.data && ( !isSelectable || isSelectable(params.data))
                    if (hasCheckbox) {
                        return <input
                            type="checkbox"
                            checked={isSelected(params.data)}
                            onChange={() => {}}
                            onClick={() => toggleSelection(params.data)}
                        ></input>
                    }
                    else {
                        return null
                    }
                }
            }
            if (selectionMode === "Multiple" && !props.hideSelectAll) {
                checkboxCol.headerComponent = CheckboxHeaderComponent
                checkboxCol.headerComponentParams = {
                    onToggle: onToggleSelectAll,
                    isHeaderChecked: isSelectAllChecked,
                    id: getIdForSelectAllCheckBox()
                }

            } else {
                checkboxCol.headerComponent = EmptyHeaderComponent
            }

            mainHeaderColumns.push(checkboxCol)
        }

        if (props.mainHeaderPrependItems) {
            for (let item of props.mainHeaderPrependItems) {
                const newItem = _.cloneDeep(item)
                newItem.pinned = "left"
                mainHeaderColumns.push(newItem)
            }
        }

        if (props.mainHeaderSectionItems) {
            for (let item of props.mainHeaderSectionItems) {
                mainHeaderColumns.push(_.cloneDeep(item))
            }
        }

        const mainHeader = hasColumnGroups()
            ? [{
                headerName: "",
                children: mainHeaderColumns
            }]
        : mainHeaderColumns

        const columnDefsCalculated: ColDef[] = mainHeader

        if (props.headerData) {
            for (let item of props.headerData) {
                columnDefsCalculated.push(_.cloneDeep(item))
            }
        }

        for (let columnDef of columnDefsCalculated) {
            if (!columnDef["children"]) {
                if (!columnDef.filterParams) {
                    columnDef.filterParams = {}
                }
                columnDef.filterParams.maxNumConditions = 1
            } else {
                for (let childDef of columnDef["children"]) {
                    if (!childDef.filterParams) {
                        childDef.filterParams = {}
                    }
                    childDef.filterParams.maxNumConditions = 1
                }
            }
        }

        return columnDefsCalculated
    }

    const columnDefs = useMemo(() => calculateColumnDefs(),
        [props.tableId, props.headerData, props.mainHeaderPrependItems, props.mainHeaderSectionItems])

    // Responding to column updates
    useEffect(() => {
        resizeColumns()
    }, [columnDefs])

    const updateScrollPositionLabel = useCallback(():void => {
        if (!gridApiRef.current) {
             return
        }
        setScrollPositionLabel(computeScrollPositionLabel(gridApiRef.current))
    }, [])


    useEffect(() => {
        setTimeout(updateScrollPositionLabel, 100)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data, props.datasource, props.currentInstrumentType])

    useEffect(()=> {
        // update select all checkbox when data changes
        // (use this only when the grid is configured with "data" not a "datasource")
        if (!props.datasource) {
            initializeSelectAllCheckbox()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    useEffect(() => {
        if (gridApiRef.current) {
            gridApiRef.current.redrawRows()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },        [props.forceRefreshToggle, props.selected])

    useEffect(() => {
        initializeSelectAllCheckbox()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[viewPortChangeFlag])

    // TODO(pfernhout) Remove this if not needed -- still unclear to me what does with highlighted
    // useEffect(() => {
    //     if (gridApiRef.current) {
    //         updateColumns(gridApiRef.current)
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [props.highlighted])

    useEffect(() => {
        // This is done via modifying the select all checkbox element directly instead of via updateColumns
        // to avoid losing any column filters and to avoid a server request for reloading the grid.
        // This assumes there won't be two multi-select Grids on the same page with the same tableId.
        const element = document.getElementById(getIdForSelectAllCheckBox())
        if (element) {
            element["checked"] = isSelectAllChecked
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelectAllChecked])

    const onGridReady = useCallback((params: GridReadyEvent) => {
        const { api } = params
        gridApiRef.current = api
        api.applyColumnState({ state: props.sortModel} )

        window.onresize = () => {
            if (gridApiRef.current && props.autoResizeColumns) {
                gridApiRef.current.sizeColumnsToFit()
            }
        }
    }, [])

    const onSortChanged = useCallback((event) => {
        const { api } = event
        const columnState = api.getColumnState()
        if (props.setSortModel) {
            props.setSortModel(columnState)
        }
        storeGridState(api, props.tableId)
    }, [props.setSortModel, props.tableId])

    const onDragStopped = useCallback(( event ) => {
        // used to store changes to width or order of columns
        const { api } = event
        storeGridState(api, props.tableId)
    }, [props.tableId])

    const onRowClicked = props.onRowClicked ?
        ( event: RowClickedEvent) => {
            const excludedCols = props.rowClickExcludedColumns || []
            const column = event.api.getFocusedCell() && event.api.getFocusedCell().column
            const clickedCol = column && column.getColId && column.getColId()
            const suppressClick = excludedCols.includes(clickedCol)
            if (!suppressClick) {
                props.onRowClicked(event)
            }
        } :
        undefined

    useImperativeHandle(ref, () => ({
        // Returns selected rows while preserving sort order
        getSortedData() {
            const gridAPI: GridApi = gridApiRef.current
            const sortedData = []
            const selectedIds = props.selected.map( data => data[props.idField])
            gridAPI.forEachNodeAfterFilterAndSort(row => {
                const isSelected = selectedIds.includes(row.data[props.idField])
                if (isSelected) {
                    sortedData.push(row.data)
                }
            })
            return sortedData
        }
    }))

    const toggleSelection = useDynamicCallback((rowData: any) => {

        const { idField, selected} = props
        const selectionHash = createSelectionHash(props.selected)
        const id = rowData[idField]
        const isBeingSelected =  (selectionHash[id] === false)
        let newSelected = [...selected]
        switch (props.selectionMode) {
            case "Multiple":
                if ( isBeingSelected ) {
                    newSelected.push(rowData)
                } else {
                    newSelected = newSelected.filter( item => item[idField] !== rowData[idField])
                }
                break
            case "Single":
                newSelected = isBeingSelected ? [rowData] : []
                break
            default: break
        }

        const newIsAllSelected = isAllSelected(newSelected)
        if (isSelectAllChecked !== newIsAllSelected) {
            setIsSelectAllChecked(newIsAllSelected)
        }

        props.onSelectionChange(newSelected, getNotSelected(newSelected, false))
    })

    const createSelectionHash = (selected: any[]): {[key: string]: boolean} => {
        if (gridApiRef.current) {
            const hash: {[key: string]: boolean} = {}
            gridApiRef.current.forEachNode( node => {
                if (node.data) {
                    const id = node.data[props.idField]
                    hash[id] = false
                }
            })
            for (let item of selected) {
                const id = item[props.idField]
                hash[id] = true
            }
            return hash
        }
        return null
    }

    const getNotSelected = (selected: any[], selectableOnly:  boolean): any[] => {
        const selectionHash = createSelectionHash(selected)
        let notSelected: any[] = []
        for (let item of allData(selectableOnly)) {
            if (item) {
                const id = item[props.idField]
                if (selectionHash[id] === false) {
                    notSelected.push(item)
                }
            }
        }
        return notSelected
    }

    const isAllSelected = (selected: any[]) => {
        if (!selected || selected.length === 0) {
            return false
        }
        if (allData(true).length === 0) {
            return false
        }
        return getNotSelected(selected, true).length === 0
    }

    const isSelected = useDynamicCallback((rowData: any): boolean => {
        const { idField, selected } = props
        return selected.find(
            item => item[idField] === rowData[idField]
        )
        !== undefined
    })

    const allData = useCallback((selectableOnly: boolean): any[] => {
        const canBeIncluded = (data: any) => {
            if (!selectableOnly || !props.isSelectable) {
                return true
            }
            return props.isSelectable(data)
        }
        let allData = []
        if ( gridApiRef.current) {
            gridApiRef.current.forEachNode(node => {
                if (node && node.data) {
                    if (canBeIncluded(node.data)) {
                        allData.push(node.data)
                    }
                }
            })
        }
        return allData
    }, [props.isSelectable])

    const resizeColumns = useCallback(() => {
        if (props.autoResizeColumns) {
            gridApiRef.current?.sizeColumnsToFit()
        }
    }, [props.autoResizeColumns])

    const onFirstDataRendered = useCallback(() => {
        resizeColumns()
        setIsGridConfigured(true)
    }, [])

    function onSearchChanged(text: string) {
        updateScrollPositionLabel()
        if (props.onSearchChanged) {
            props.onSearchChanged(text)
        }
    }

    // Enclosing component should copy the data if it needs to be copied
    // let data: any = props.data

    const onViewportChanged = useCallback(() => {
        if (allData(false).length > 0) {
            setViewPortChangeFlag(oldFlag => !oldFlag)
        }
        // ignoring allData may change but using props.isSelectable instead which it depends on
    }, [props.isSelectable])

    function initializeSelectAllCheckbox() {
        if (props.selectionMode === "Multiple") {
            const checked = isAllSelected(props.selected)
            setIsSelectAllChecked(checked)
        }
    }

    const onGridPreDestroyed = useCallback(() => {
        gridApiRef.current = null
    }, [])

    const components = useMemo(() => ({
        agColumnHeader: datasource ? SearchColumnHeader : undefined
    }), [datasource])

    const onFilterChanged = useCallback((event) => {
        storeGridState(event.api, props.tableId)
    }, [props.tableId])

    const getRowId = useCallback(params => params.data[props.idField] ,
        [props.idField])

    const icons = useMemo(() => ({sortUnSort: "⇵"}), [])

    return (
        <Fragment>
            <div
                className="btn-toolbar align-items-center mb-2 mt-3"
                role="toolbar"
                aria-label="toolbar with button groups and buttons"
            >
                {props.hasCart && props.numSelected !== undefined && (
                    <BadgeButton
                        count={props.numSelected}
                        onClick={props.onCartBtnClicked}
                    />
                )}
                { props.children && props.children }
                { !props.children &&
                    <h2 className="font-weight-bold mb-0 ml-3" style={{ flex: 1 }}>
                        {props.tableTitle}
                    </h2>
                }
                {!props.hideScrollPositionLabel &&
                    <div className="float-left"> {scrollPositionLabel} </div>
                }
                {!props.disableSearch && (
                    <Search
                        id={props.searchId}
                        placeholder={props.searchBarPlaceholder || "Search ..."}
                        gridRef={gridApiRef.current}
                        onSearchChange={onSearchChanged}
                        isLocalSearch={!datasource}
                    />
                )}
            </div>
            <div
                className="ag-theme-alpine"
                style={{ height: props.gridHeight || "90%", width: "100%" }}
            >
                <AgGridReact
                    components={components}
                    icons={icons}
                    paginationPageSize={defaultPageSize}
                    defaultColDef={defaultColumnDef}
                    columnDefs = {columnDefs}
                    rowData={datasource ? null : props.data}
                    gridOptions={{
                        unSortIcon: true,
                        rowModelType: (datasource ? "infinite" : undefined)
                    }}
                    getRowId = {getRowId}
                    datasource={datasource}
                    suppressRowClickSelection={true}
                    paginationAutoPageSize={true}
                    pagination={false}
                    overlayLoadingTemplate={
                        '<span class="ag-overlay-loading-center">Loading...</span>'
                    }
                    onGridReady={onGridReady}
                    onFirstDataRendered={onFirstDataRendered}
                    onFilterChanged={onFilterChanged}
                    onComponentStateChanged = { onComponentStateChanged }
                    onViewportChanged = {onViewportChanged}
                    onSortChanged={onSortChanged}
                    onBodyScroll={updateScrollPositionLabel}
                    rowBuffer={0} // required for scroll calculation and performance hit is negligible
                    onDragStopped={onDragStopped}
                    scrollbarWidth={10}
                    getRowClass = {getRowClass}
                    overlayNoRowsTemplate = {props.overlayNoRowsTemplate}
                    domLayout = {props.domLayout}
                    context = {props.context}
                    suppressCellFocus = { props.suppressCellFocus}
                    getRowStyle = {props.getRowStyle}
                    fullWidthCellRenderer = { props.fullWidthCellRenderer}
                    isFullWidthRow = { props.isFullWidthRow}
                    getRowHeight = { props.getRowHeight}
                    alwaysShowHorizontalScroll={props.alwaysShowHorizontalScroll}
                    onRowClicked = { onRowClicked }
                    tooltipShowDelay = {0}
                    onGridPreDestroyed={onGridPreDestroyed}
                />

                <div ref={stabilityElementRef} style={{display: "none"}}> Stability element </div>

                <div
                    style={{display: "none"}}
                    className = { props.loading ? `${props.tableId}_isLoading` : `${props.tableId}_notLoading`}
                >
                    Loading element
                </div>

            </div>
        </Fragment>
    )
})