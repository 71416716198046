import { v4 } from "uuid"
import moment from "moment"
import { appConfig } from "../../../core/config/app-config"
import { GENERAL_PROJECT_ID } from "../../../data/services/project-service"
import { AutomationParameter, SupportedExecutionModes } from "../../../data/model/run-design-model"
import { SequencingKit } from "../../../data/model/parts-model"
import { AnalysisRequest } from "../../../data/model/job-options-model"
import { InstrumentType } from "../../../data/model/instrument-type-model"

export const radioLabels = {
    isoSeqExperiment: {YES: "YES", NO: "NO"},
    usePreExtension: { YES: "YES", NO: "NO" },
    includeAllReads: { YES: "YES", NO: "NO" },
    includeKinetics: { YES: "YES", NO: "NO" },
    include5mC: { YES: "YES", NO: "NO" },
    readSegmentation: { YES: "YES", NO: "NO" },
    scIsoSeq: { YES: "YES", NO: "NO" },
    cellType: { human: "HUMAN", mouse: "MOUSE" },
    plateNumber: { 1: "Plate 1", 2: "Plate 2" },
    detectHeteroduplex: { YES: "YES", NO: "NO" },
    enableCCS: { OnInstrument: "On Instrument", OffInstrument: "In SMRT Link", None: "Do Not Generate" },
    demuxBarcodes: { OnInstrument: "On Instrument", None: "Do Not Generate" },
    usePredictiveLoading: { YES: "YES", NO: "NO" },
    sampleIsBarcoded: { YES: "YES", NO: "NO" },
    sameBarcodesBothEnds: { YES: "YES", NO: "NO" },
    AutoAnalysisEnabled: { YES: "YES", NO: "NO" },
    hasAnalysis: { YES: "YES", NO: "NO" },
    consensusMode: { molecule: "MOLECULE", strand: "STRAND" }
}

export const labels = {
    runName: "Run Name",
    plate1Barcode: "Plate 1",
    plate2Barcode: "Plate 2",
    plateNumber: "Reagent Plate",
    plateWell: "Plate Well",
    runDescription: "Run Comments",
    experimentName: "Experiment Name",
    experimentId: "Transfer Subdirectory",
    systemName: "Instrument Type",
    sampleName: "Well Sample Name",
    biosampleName: "Bio Sample Name",
    sampleDescription: "Sample Comment",
    libraryType: "Library Type",
    isoSeqExperiment: "Iso-Seq Experiment",
    insertSize: "Insert Size (bp)",
    adapter: "Adapter Options",
    readSegmentation: "Launch Read Segmentation",
    scIsoSeq: "Launch Single-Cell Iso-Seq Analysis",
    cellType: "Sample Type",
    onPlateLoadingConcentration: "On-Plate Loading Concentration (pM)",
    usePreExtension: "Use Pre-Extension",
    recommendedExtensionTime: "Recommended Pre-Extension Time (hours)",
    extensionTime: "Pre-Extension Time (hours)",
    projectId: "Add Data to Project",
    enableCCS: "Generate HiFi reads",
    usePredictiveLoading: "Use Adaptive Loading",
    loadingTarget: "Loading Target (P1 + P2)",
    maxLoadingTime: "Maximum Loading Time (hours)",
    movieTime: "Movie Time per SMRT Cell (hours)",
    immobilizationTime: "Immobilization Time (hrs)",
    includeAllReads: "CCS Analysis Output - Include Low Quality Reads",
    includeKinetics: "CCS Analysis Output - Include Kinetics Information",
    include5mC: "Include 5mC Calls in CpG Motifs",
    detectHeteroduplex: "Detect and Resolve Heteroduplex Reads",
    emitSubreadsPercent: "Emitted Subreads Percent",
    sampleIsBarcoded: "Sample Is Barcoded",
    barcodeSet: "Barcodes",
    sameBarcodesBothEnds: "Same Barcodes on Both Ends of Sequence",
    barcodeCSVDownload: "Autofilled Barcode Name File",
    barcodeCSVUpload: "Barcoded Sample Name",
    barcodeCSVFileName: "Barcoded Sample Name File",
    demuxBarcodes: "Demultiplex Barcodes",
    barcodeSampleMap: "Assign Bio Sample Names to Barcodes",
    wellName: "Sample Well",
    automationName: "Automation Name",
    templatePrepKit: "SMRTbell Adapter Design",
    sequencingKit: "Sequencing Kit",
    controlKit: "DNA Control Complex",
    bindingKit: "Binding Kit",
    application: "Application",
    minBarcodeScore: "Minimum Barcode Score",
    autoAnalysisEnabled: "Automatic Launch of SARS-CoV-2 Analysis",
    analysisName: "Analysis Name",
    hasAnalysis: "Add Analysis",
    workflow: "Select Analysis Workflow",
    consensusMode: "Consensus Mode",
    plate1Lot: "Plate 1 Lot",
    plate1Serial: "Plate 1 Serial",
    plate1Expiry: "Plate 1 Expiry",
    plate2Lot: "Plate 2 Lot",
    plate2Serial: "Plate 2 Serial",
    plate2Expiry: "Plate 2 Expiry",
    advanced: "Advanced"
}

export const labelsRevio = {
    sampleName: "Well Name",
    sampleDescription: "Well Comment",
    bindingKit: "Polymerase Kit",
    barcodeSet: "Indexes",
    barcodeSampleMap: "Biosample names",
    onPlateLoadingConcentration: "Library Concentration (pM)",
    movieTime: "Movie Acquisition Time (hours)",
    includeKinetics: "Include Base Kinetics",
    projectId: "Assign Data To Project",
    consensusMode: "Consensus Mode"
}

export const revioDefaults: SampleForm = {
    id: v4(),
    ccsUuid: v4(),
    multiJobId: 0,
    isSampleOpen: true,
    isAdvancedOpen: false,
    isRunOptionsOpen: false,
    isAnalysisOpen: false,
    isBarcodeOpen: false,
    isAdvancedBarcodeOpen: false,
    sampleName: "",
    application: "",
    workflow: "",
    sampleDescription: "",
    libraryType: "Standard",
    wellName: "A01",
    plateNumber: "1",
    plateWell: "1_A01",
    biosampleName: "",
    automationName: "",
    templatePrepKit: "Lxxxxx999999001123199",
    bindingKit: "Lxxxxx102739100123199",
    sequencingKit: "",
    controlKit: "Lxxxxx102798000123199",
    isoSeqExperiment: "NO",
    insertSize: null,
    projectId: GENERAL_PROJECT_ID,
    onPlateLoadingConcentration: null,
    movieTime: 24,
    usePreExtension: "NO",
    extensionTime: 0,
    recommendedExtensionTime: "0",
    readout: null,
    metricsVerbosity: null,
    enableCCS: SupportedExecutionModes.oninstrument,
    usePredictiveLoading: "YES",
    loadingTarget: appConfig.defaultLoadingTarget,
    maxLoadingTime: 2,
    immobilizationTime: "2",
    includeAllReads: "NO",
    includeKinetics: "NO",
    include5mC: "NO",
    detectHeteroduplex: "NO",
    emitSubreadsPercent: 0,
    sampleGuid: "",
    sampleIsBarcoded: localStorage.getItem("sampleIsBarcodedRevio") || "YES",
    barcodeSet: "43f950a9-8bde-3855-6b25-c13368069745",
    barcodeSetName: "",
    sameBarcodesBothEnds: "YES",
    barcodeCSVFile: null,
    sarsBarcodeFile: null,
    barcodeCSVFileName: "",
    barcodeSampleMap: {},
    assayPlateIdMap: {},
    assayPlateWellMap: {},
    barcodeUuidMap: {},
    adapter: "mas16",
    readSegmentation: "NO",
    scIsoSeq: "NO",
    cellType: "human",
    demuxBarcodes: "OnInstrument",
    isValidBarcodeDesign: true,
    minBarcodeScore: 80,
    copyFiles: [],
    copyFilesPreset: "Production",
    AutoAnalysisEnabled: "NO",
    AutoAnalysisName: "",
    hasAutoAnalyses: false,
    hasAnalysis: "NO",
    automationParameters: [],
    hasAnalysisOptionsError: false,
    consensusMode: "molecule"
}

export const vegaDefaults: SampleForm = {
    id: v4(),
    ccsUuid: v4(),
    multiJobId: 0,
    isSampleOpen: true,
    isAdvancedOpen: false,
    isRunOptionsOpen: false,
    isAnalysisOpen: false,
    isBarcodeOpen: false,
    isAdvancedBarcodeOpen: false,
    sampleName: "",
    application: "",
    workflow: "",
    sampleDescription: "",
    libraryType: "Standard",
    wellName: "A01",
    plateNumber: "1",
    plateWell: "1_A01",
    biosampleName: "",
    automationName: "",
    templatePrepKit: "Lxxxxx999999001123199",
    bindingKit: "Lxxxxx103426500123199",
    sequencingKit: "",
    controlKit: "Lxxxxx102798000123199",
    isoSeqExperiment: "NO",
    insertSize: null,
    projectId: GENERAL_PROJECT_ID,
    onPlateLoadingConcentration: null,
    movieTime: 24,
    usePreExtension: "NO",
    extensionTime: 0,
    recommendedExtensionTime: "0",
    readout: null,
    metricsVerbosity: null,
    enableCCS: SupportedExecutionModes.oninstrument,
    usePredictiveLoading: "NO",  // FUTURE not supported for FCR
    loadingTarget: appConfig.defaultLoadingTarget,
    maxLoadingTime: 2,
    immobilizationTime: "2",
    includeAllReads: "NO",
    includeKinetics: "NO",
    include5mC: "NO",
    detectHeteroduplex: "NO",
    emitSubreadsPercent: 0,
    sampleGuid: "",
    sampleIsBarcoded: localStorage.getItem("sampleIsBarcodedRevio") || "YES",
    barcodeSet: "43f950a9-8bde-3855-6b25-c13368069745",
    barcodeSetName: "",
    sameBarcodesBothEnds: "YES",
    barcodeCSVFile: null,
    sarsBarcodeFile: null,
    barcodeCSVFileName: "",
    barcodeSampleMap: {},
    assayPlateIdMap: {},
    assayPlateWellMap: {},
    barcodeUuidMap: {},
    adapter: "mas16",
    readSegmentation: "NO",
    scIsoSeq: "NO",
    cellType: "human",
    demuxBarcodes: "OnInstrument",
    isValidBarcodeDesign: true,
    minBarcodeScore: 80,
    copyFiles: [],
    copyFilesPreset: "Production",
    AutoAnalysisEnabled: "NO",
    AutoAnalysisName: "",
    hasAutoAnalyses: false,
    hasAnalysis: "NO",
    automationParameters: [],
    hasAnalysisOptionsError: false,
    consensusMode: "molecule"
}

export const blankSample: SampleForm = {
    id: v4(),
    ccsUuid: v4(),
    multiJobId: 0,
    isSampleOpen: true,
    isAdvancedOpen: false,
    isRunOptionsOpen: false,
    isAnalysisOpen: false,
    isBarcodeOpen: false,
    isAdvancedBarcodeOpen: false,
    sampleName: "",
    application: "",
    workflow: "",
    sampleDescription: "",
    libraryType: "Standard",
    wellName: "A01",
    plateNumber: "1",
    plateWell: "1_A01",
    biosampleName: "",
    automationName: "",
    templatePrepKit: "",
    bindingKit: "",
    sequencingKit: "",
    controlKit: "",
    isoSeqExperiment: "NO",
    insertSize: null,
    projectId: GENERAL_PROJECT_ID,
    onPlateLoadingConcentration: 0,
    movieTime: 24,
    usePreExtension: "NO",
    extensionTime: 0,
    recommendedExtensionTime: "0",
    readout: null,
    metricsVerbosity: null,
    enableCCS: "None",
    usePredictiveLoading: "NO",
    loadingTarget: appConfig.defaultLoadingTarget,
    maxLoadingTime: 2,
    immobilizationTime: "2",
    includeAllReads: "NO",
    includeKinetics: "NO",
    include5mC: "NO",
    detectHeteroduplex: "NO",
    emitSubreadsPercent: 0,
    sampleGuid: "",
    sampleIsBarcoded: "NO",
    barcodeSet: "",
    barcodeSetName: "",
    sameBarcodesBothEnds: "YES",
    barcodeCSVFile: null,
    sarsBarcodeFile: null,
    barcodeCSVFileName: "",
    barcodeSampleMap: {},
    assayPlateIdMap: {},
    assayPlateWellMap: {},
    barcodeUuidMap: {},
    adapter: "mas16",
    readSegmentation: "NO",
    scIsoSeq: "NO",
    cellType: "human",
    demuxBarcodes: "None",
    isValidBarcodeDesign: true,
    minBarcodeScore: 80,
    copyFiles: [],
    copyFilesPreset: "Production",
    AutoAnalysisEnabled: "NO", // Not related to auto-analysis!
    AutoAnalysisName: "", // Not related to auto-analysis!
    hasAutoAnalyses: false, // Not related to auto-analysis!
    hasAnalysis: "NO",
    automationParameters: [],
    hasAnalysisOptionsError: false,
    consensusMode: "molecule"
}

export const blankForm: RunForm = {
    runName: `Run ${moment().format("MM.DD.YYYY HH:mm")}`,
    plate1: null,
    plate2: null,
    plate1Barcode: "",
    plate1PartNumber: "",
    plate1Lot: "",
    plate1Serial: "",
    plate1Expiry: "",
    plate2Barcode: "",
    plate2PartNumber: "",
    plate2Lot: "",
    plate2Serial: "",
    plate2Expiry: "",
    plate1Label: "",
    plate2Label: "",
    assignedWells: [],
    usedWells: [],
    runDescription: "",
    experimentName: "",
    experimentId: "",
    systemName: "Revio",
    id: v4(),
    multiJobId: 0,
    reserved: false,
    usePredictiveLoading: "YES",
    samples: [blankSample]
}

export interface RunForm {
    runName: string
    plate1: SequencingKit
    plate2: SequencingKit
    plate1Barcode: string
    plate1PartNumber: string
    plate1Lot: string
    plate1Serial: string
    plate1Expiry: string
    plate2Barcode: string
    plate2PartNumber: string
    plate2Lot: string
    plate2Serial: string
    plate2Expiry: string
    plate1Label: string
    plate2Label: string
    assignedWells: string[]
    usedWells: string[]
    runDescription: string
    experimentName: string
    experimentId: string
    systemName: InstrumentType
    id: string
    multiJobId: number
    reserved: boolean
    usePredictiveLoading: string
    samples: SampleForm[]
}

export interface SampleForm extends AnalysisRequest {
    id: string
    ccsUuid: string
    multiJobId: number
    isSampleOpen: boolean
    isAdvancedOpen: boolean
    isRunOptionsOpen: boolean
    isAnalysisOpen: boolean
    isBarcodeOpen: boolean
    isAdvancedBarcodeOpen: boolean
    application: string
    workflow: string
    sampleName: string
    sampleDescription: string
    libraryType: string
    wellName: string
    plateNumber: string
    plateWell: string
    biosampleName: string
    automationName: string
    templatePrepKit: string
    bindingKit: string
    sequencingKit: string
    controlKit: string
    isoSeqExperiment: string
    insertSize: number
    projectId: number
    onPlateLoadingConcentration: number
    movieTime: number
    usePreExtension: string
    extensionTime: number | string
    recommendedExtensionTime: string
    readout: string
    metricsVerbosity: string
    enableCCS: string
    usePredictiveLoading: string
    loadingTarget: number
    maxLoadingTime: number
    immobilizationTime: string
    includeAllReads: string
    includeKinetics: string
    include5mC: string
    detectHeteroduplex: string
    emitSubreadsPercent: number
    sampleGuid: string
    adapter: string
    readSegmentation: string
    scIsoSeq: string
    cellType: string
    sampleIsBarcoded: string
    barcodeSet: string
    barcodeSetName: string
    sameBarcodesBothEnds: string
    barcodeCSVFile: object
    sarsBarcodeFile: object
    barcodeCSVFileName: string
    barcodeSampleMap: {}
    barcodeUuidMap: {}
    assayPlateIdMap: {}
    assayPlateWellMap: {}
    demuxBarcodes: string
    isValidBarcodeDesign: boolean
    minBarcodeScore: number
    copyFiles: string[]
    copyFilesPreset: string
    AutoAnalysisEnabled: string // Unrelated to auto-analysis options in RD!
    AutoAnalysisName: string // Unrelated to auto-analysis options in RD!
    hasAutoAnalyses: boolean // Unrelated to auto-analysis options in RD!
    hasAnalysis: "YES" | "NO"
    automationParameters: AutomationParameter[]
    hasAnalysisOptionsError: boolean
    consensusMode: string
    subreadToHiFiPileup?: boolean
    fullResolutionBaseQual?: boolean
}

export const minMovieTimeInternal = 0.01
export const maxMovieTimeInternal = 40
export const minMovieTimeVegaInternal = 0.167
export const maxMovieTimeVegaInternal = 45
export const minMovieTime = appConfig.movieTimeLowerLimit
export const maxMovieTime = 30
export const movieTimesRevio = [12, 24, 30]
export const movieTimesVega = [12, 24]

export const allRevioWells = [
    "1_A01", "1_B01", "1_C01", "1_D01",
    "2_A01", "2_B01", "2_C01", "2_D01"
]

export const plateWellList = [
    {id: "1_A01", name: "Plate 1, Well A01"},
    {id: "1_B01", name: "Plate 1, Well B01"},
    {id: "1_C01", name: "Plate 1, Well C01"},
    {id: "1_D01", name: "Plate 1, Well D01"},
    {id: "2_A01", name: "Plate 2, Well A01"},
    {id: "2_B01", name: "Plate 2, Well B01"},
    {id: "2_C01", name: "Plate 2, Well C01"},
    {id: "2_D01", name: "Plate 2, Well D01"}
]

export const libraryTypes =
    [
        {id: "Standard", name: "Standard"},
        {id: "masSeq", name: "Kinnex"},
        {id: "AAV", name: "Adeno-associated Virus"}
    ]

export const libraryTypeMap = {
    Standard: "Standard",
    masSeq: "Kinnex",
    AAV: "Adeno-associated Virus"
}

export const SARS_WORFLOW_ID = "cromwell.workflows.pb_sars_cov2_kit"

const TWIST_BC_UUID = "cfbe0985-c5f7-c1e1-bb3a-6432fbefac5d"
const KINNEX_16S_384_BC_UUID = "965b3839-7a1f-c26c-2183-3d0f4ee9ac02"

export const ASYMM_BC_UUIDS = [TWIST_BC_UUID, KINNEX_16S_384_BC_UUID]

export const oldPartNumbers = [
    "100-620-000",  // Sequencing Plate 1.0
    "100-867-300",  // Sequencing Plate 1.1
    "100-902-100",  // Sequencing Plate 1.2
    "100-972-200",  // Sequencing Plate 1.3
    "100-861-800",  // Sequencing Plate 2.0
    "101-093-700",  // Sequencing Plate 2.0 (4 rxn)
    "101-309-500",  // Sequencing Plate 2.1
    "101-309-400",  // Sequencing Plate 2.1 (4 rxn)
    "100-866-100",  // SMRT Cell Extended Use Plate
    "100-925-300",  // Sequencing Plate Biotin Depletion
    "100-807-900",  // Sequencing Verification Plate
    "100-861-700"]  // Sequencing Plate 1.1

export const ORDERED_HEADERS = [
    "version",
    "internalMode",
    "experimentName",
    "experimentId",
    "experimentDescription",
    "application",
    "libraryType",
    "runName",
    "systemName",
    "runDescription",
    "plate1",
    "plate2",
    "isCollection",
    "wellName",
    "plateNumber",
    "sampleName",
    "sampleDescription",
    "projectId",
    "collectionTime",
    "insertSize",
    "loadingConcentration",
    "includeAllReads",
    "includeKinetics",
    "include5mC",
    "detectHeteroduplex",
    "useDynamicLoading",
    "consensusMode",
    "adapter",
    "readSegmentation",
    "scIsoSeq",
    "cellType",
    "loadingTarget",
    "maxLoadingTime",
    "prepKitBarcode",
    "controlKitBarcode",
    "bindingKitBarcode",
    "sequencingKitBarcode",
    "automationParameters",
    "enableCCS",
    "demuxBarcodes",
    "isBarcoded",
    "barcodeUuid",
    "sameBarcodesBothEnds",
    "barcodeName",
    "biosampleName",
    "sampleTags",
    "minBarcodeScore",
    "pipelineId",
    "analysisName",
    "entryPoints",
    "taskOptions",
    "presetId",
    "isBarcoded",
    "fullResolutionBaseQual",
    "subreadToHiFiPileup",
    "copyFilesPreset"
]

export const HEADERS_REVIO = {
    experimentName: "Experiment Name",
    experimentId: "Transfer Subdirectory",
    experimentDescription: "Experiment Description",
    application: "Application",
    libraryType: "Library Type",
    runName: "Run Name",
    systemName: "Instrument Type",
    runDescription: "Run Comments",
    plate1: "Plate 1",
    plate2: "Plate 2",
    plateNumber: "Reagent Plate",
    isCollection: "Is Collection",
    wellName: "Sample Well",
    sampleName: "Well Name",
    sampleDescription: "Well Comment",
    collectionTime: "Movie Acquisition Time (hours)",
    insertSize: "Insert Size (bp)",
    internalMode: "Internal",
    loadingConcentration: "Library Concentration (pM)",
    includeKinetics: "Include Base Kinetics",
    bindingKitBarcode: "Polymerase Kit",
    bindingKitParameters: "Binding Kit Parameters",
    sequencingKitBarcode: "Sequencing Kit Box Barcode",
    sequencingKitParameters: "Sequencing Kit Parameters",
    automationName: "Automation Name",
    automationParameters: "Automation Parameters",
    primaryAnalysis: "Primary Analysis",
    primaryAnalysisParameters: "Primary Analysis Parameters",
    secondaryAnalysis: "Secondary Analysis",
    secondaryAnalysisParameters: "Secondary Analysis Parameters",
    barcodeUuid: "Indexes",
    barcodeSampleMap: "Barcoded Sample Names",
    barcodeName: "Barcode Name",
    biosampleName: "Bio Sample Name",
    sampleTags: "Sample Tags",
    minBarcodeScore: "Minimum Barcode Score",
    adapter: "Adapter Options",
    readSegmentation: "Launch Read Segmentation",
    scIsoSeq: "Launch Single-Cell Iso-Seq Analysis",
    cellType: "Sample Type",
    pipelineId: "Pipeline Id",
    analysisName: "Analysis Name",
    entryPoints: "Entry Points",
    taskOptions: "Task Options",
    projectId: "Assign Data To Project",
    emitSubreadsPercent: "Emitted Subreads Percent",
    // XXX obsolete but still allowed (just ignored)
    sizeSelection: "Size Selection",
    washKitBarcode: "Wash Kit Box Barcode",
    washKitParameters: "Wash Kit Parameters",
    // this one gets special handling
    enableCCS: "Generate HiFi reads",
    version: "CSV Version",
    presetId: "Compute Settings",
    isBarcoded: "Sample is indexed",
    useDynamicLoading: "Use Adaptive Loading",
    consensusMode: "Consensus Mode",
    fullResolutionBaseQual: "Full Resolution Base Qual",
    subreadToHiFiPileup: "Subread To HiFi Pileup",
    sameBarcodesBothEnds: "Same Barcodes on Both Ends of Sequence",
    copyFilesPreset: "Copy Files Preset"
}

export const REQUIRED_HEADERS_REVIO = [
    HEADERS_REVIO.runName,
    HEADERS_REVIO.wellName,
    HEADERS_REVIO.sampleName,
    HEADERS_REVIO.biosampleName,
    HEADERS_REVIO.collectionTime,
    HEADERS_REVIO.bindingKitBarcode,
    HEADERS_REVIO.plate1,
    HEADERS_REVIO.plateNumber,
    HEADERS_REVIO.libraryType,
    HEADERS_REVIO.insertSize
]

export const RUN_SETTINGS_HEADERS = [
    HEADERS_REVIO.runName,
    HEADERS_REVIO.runDescription,
    HEADERS_REVIO.plate1,
    HEADERS_REVIO.plate2,
    HEADERS_REVIO.experimentName,
    HEADERS_REVIO.experimentId,
    HEADERS_REVIO.experimentDescription,
    HEADERS_REVIO.internalMode,
    HEADERS_REVIO.version
]

export const COLLECTION_SETTINGS_HEADERS = [
    HEADERS_REVIO.sampleName,
    HEADERS_REVIO.sampleDescription,
    HEADERS_REVIO.application,
    HEADERS_REVIO.libraryType,
    HEADERS_REVIO.collectionTime,
    HEADERS_REVIO.insertSize,
    HEADERS_REVIO.projectId,
    HEADERS_REVIO.loadingConcentration,
    HEADERS_REVIO.includeKinetics,
    HEADERS_REVIO.bindingKitBarcode,
    HEADERS_REVIO.automationParameters,
    HEADERS_REVIO.barcodeUuid,
    HEADERS_REVIO.automationName,
    HEADERS_REVIO.emitSubreadsPercent,
    HEADERS_REVIO.minBarcodeScore,
    HEADERS_REVIO.pipelineId,
    HEADERS_REVIO.analysisName,
    HEADERS_REVIO.entryPoints,
    HEADERS_REVIO.taskOptions,
    HEADERS_REVIO.presetId,
    HEADERS_REVIO.biosampleName,
    HEADERS_REVIO.isBarcoded,
    HEADERS_REVIO.useDynamicLoading,
    HEADERS_REVIO.consensusMode,
    HEADERS_REVIO.fullResolutionBaseQual,
    HEADERS_REVIO.subreadToHiFiPileup,
    HEADERS_REVIO.sameBarcodesBothEnds,
    HEADERS_REVIO.copyFilesPreset
]

export interface CsvDataObject {
    experimentName?: any
    experimentId?: any
    experimentDescription?: any
    experimentType?: any
    plate1?: any
    plate2?: any
    plateNumber?: any
    application?: any
    includeKinetics?: any
    include5mC?: any
    detectHeteroduplex?: any
    includeAllReads?: any
    projectId?: any
    enableCCS?: any
    demuxBarcodes?: any
    runName?: any
    systemName?: any
    runDescription?: any
    isCollection?: any
    wellName?: any
    cellName?: any
    sampleName?: any
    sampleDescription?: any
    collectionTime?: any
    insertSize?: any
    loadingConcentration?: any
    useDynamicLoading?: any
    loadingTarget?: any
    maxLoadingTime?: any
    sizeSelection?: any
    stageStart?: any
    reuseSample?: any
    prepKitBarcode?: any
    prepKitParameters?: any
    controlKitBarcode?: any
    controlKitParameters?: any
    bindingKitBarcode?: any
    bindingKitParameters?: any
    sequencingKitBarcode?: any
    sequencingKitParameters?: any
    washKitBarcode?: any
    washKitParameters?: any
    automationName?: any
    automationParameters?: any
    primaryAnalysis?: any
    primaryAnalysisParameters?: any
    secondaryAnalysis?: any
    secondaryAnalysisParameters?: any
    isBarcoded?: any
    barcodeUuid?: any
    sameBarcodesBothEnds?: any
    barcodeSampleMap?: any
    barcodeName?: any
    minBarcodeScore?: any
    biosampleName?: any
    sampleTags?: any
    pipelineId?: any
    analysisName?: any
    entryPoints?: any
    taskOptions?: any
    adapter?: any
    readSegmentation?: any
    scIsoSeq?: any
    cellType?: any
    libraryType?: any
    version?: any
    presetId?: any
    consensusMode?: any
    fullResolutionBaseQual?: any
    subreadToHiFiPileup?: any
    internalMode?: any
    copyFilesPreset?: any
}

export const primaryParametersMap: any = {
    readout: "Readout",
    metricsverbosity: "MetricsVerbosity",
    copyfiletrace: "CopyFileTrace",
    copyfilebaz: "CopyFileBaz",
    copyfiledarkframe: "CopyFileDarkFrame",
    copystatsh5: "CopyStatsH5"
}

export const validPrimaryParams = Object.keys(primaryParametersMap).map(key => primaryParametersMap[key]).join(", ")

export const readoutMap: any = {
    pulses: "Pulses",
    bases: "Bases",
    baseswithoutqvs: "Bases_Without_QVs",
    bases_without_qvs: "Bases_Without_QVs"
}

export const metricsMap: any = {
    minimal: "Minimal",
    high: "High",
    none: "None"
}

export const validReadoutValues = Object.keys(readoutMap).map(key => readoutMap[key]).join(", ")
export const validMetricsValues = Object.keys(metricsMap).map(key => metricsMap[key]).join(", ")

export const booleanMap: any = {
    "true": true,
    "t": true,
    "y": true,
    "yes": true,
    "false": false,
    "f": false,
    "n": false,
    "no": false
}

export const illegalPathCharsRE = /[<>:"\\|?*]/g
export const spacesRE = / /g
export const illegalPathSeparatorsRE = /(?:^\/)|\/\/|(?:\/$)/
export const wellNameRE = /^[A-H](?:0[1-9]|1[0-2])$/
export const stringFieldRE = /^[a-zA-Z0-9'_.\s:-]+$/
export const sampleFieldRE = /[a-zA-Z0-9-_]/g
export const sampleFieldREFilter = /[^a-zA-Z0-9-_]/g
export const sampleFieldREValidator = /^[a-zA-Z0-9-_]+$/

export const paramTypeMap: any = {
    string: "String",
    int: "Int32",
    uint: "UInt32",
    double: "Double",
    float: "Single",
    boolean: "Boolean",
    datetime: "DateTime"
}

export const customerFacingAutomationParameters = [
    "ImmobilizationTime",
    "ExtendFirst",
    "ExtensionTime"
]

export const maxSequencingKits = 2

export const masSeqEntryPoints = [
    {entryId: "eid_barcode", fileTypeId: "PacBio.DataSet.BarcodeSet", uuid: "10ce52cd-91c5-4bb9-4cac-2233832ecb12"},
    {entryId: "eid_barcode_2", fileTypeId: "PacBio.DataSet.BarcodeSet", uuid: "3332ee2d-56dc-07b0-fbcc-d7f6158a7a65"},
    {entryId: "eid_ref_dataset", fileTypeId: "PacBio.DataSet.ReferenceSet", uuid: "ba3866bf-2aba-7c99-0570-0d6709174e4a"}
]

export const plateBarcodeOneReaction = ["102412400", "103496800"]

export const plateToolTip = "Use the fields below to select the sequencing plate and enter the plate identifier information" +
    " (Lot, Serial, Expiry) which you can find on the plate label. To enter these fields using the QR code, click the scan button to activate your camera."


export const v2SeqKits = ["103496700", "103496800"]
export const v1SeqKits = ["102118800", "102412400"]
export const v2ControlKit = "Lxxxxx103508800123199"
export const v2PolKit = "Lxxxxx103496900123199"
export const v1ControlKit = "Lxxxxx102798000123199"
export const v1PolKit = "Lxxxxx102739100123199"

const HIFI_TARGET_ENRICHMENT_ID =
    "cromwell.workflows.pb_target_enrichment"
export const MULTIPLEXED_WORKFLOWS = [HIFI_TARGET_ENRICHMENT_ID]

export const PRE_ANALYSIS_WORKFLOW = ["cromwell.workflows.pb_demux_subreads",
    "cromwell.workflows.pb_ccs_demux",
    "cromwell.workflows.pb_ccs",
    "cromwell.workflows.pb_export_ccs",
    "cromwell.workflows.pb_demux_ccs"]